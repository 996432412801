/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import Image from '../../../shared/Image';
import SubTitle from '../../../shared/UIKit/SubTitle';

// Style
import './index.scss';
// Assets

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Content() {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // State

  // Other hooks like reux or localization ...
  const { t } = useTranslation();
  const {
    content: { title, contentList },
  } = t('brainHealth', { returnObjects: true });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */

  /* ***************************** RENDER HELPERS ***************************** */
  function Paragraphs() {
    return contentList.map(({ id, text }) => (
      <p className="brain-health-content__paragraph" key={id}>
        {text}
      </p>
    ));
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="brain-health-content brain-health-content-container scroll-reveal">
      <Row justify="center">
        <SubTitle>{title}</SubTitle>
      </Row>
      <Row align="start" className="brain-health-content-row">
        <Col lg={6} md={12} sm={12} className="brain-health-content-col">
          <Paragraphs />
        </Col>
        <Col
          lg={6}
          md={12}
          sm={12}
          className="brain-health-content-col brain-health-content-col__img"
        >
          <Image src="brain-health/brain.png" alt="" aria-hidden="true" />
        </Col>
      </Row>
    </Container>
  );
}

Content.propTypes = {};

Content.defaultProps = {};

export default Content;
