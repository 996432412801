/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
// UI lib comoponents
import { Container } from 'react-grid-system';

// Local UI components

// Redux actions and selectors

// Helpers & utils

// Style
import './index.scss';
// Assets
import LOGO from '../../../images/brain-health/Logo.svg';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Hero() {
  /* ******************************** CONSTANTS ******************************* */
  const { t } = useTranslation();
  const {
    Hero: { description },
  } = t('brainHealth', { returnObjects: true });
  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */

  /* ***************************** RENDER HELPERS ***************************** */

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="brain-health-hero scroll-reveal" fluid>
      <img
        src={LOGO}
        alt="brain-health__logo"
        className="brain-health-hero__logo"
      />
      <p className="brain-health-hero__description">{description}</p>
    </Container>
  );
}

Hero.propTypes = {};

Hero.defaultProps = {};

export default Hero;
